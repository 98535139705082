import React from 'react'
import Layout from '../../components/Layout'

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1 className="mt-8 mb-4 text-3xl md:text-4xl">Thank you!</h1>
          <p>
            Your message has been submitted. We will respond as soon as we can.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)
